import React from 'react';
import {Breadcrumb, Button, Col, Form, Row} from 'react-bootstrap';
import http from "../../../services/HttpService";
import moment from 'moment-timezone'
import {TIME_ZONE} from "../../../utils/DateUtil";
import loader from '../../../images/loader.gif'
import CKEditor from 'ckeditor4-react';
import ToastUtil from "../../../utils/ToastUtil";
import MediasModal from "../media/MediasModal";
import {getParams} from "../../../utils/UrlUtil";
import {CLIENTS} from "../../../constants/constants";
import HasFeaturePermission from "../../user/HasFeaturePermission";


class CreateOrEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            blog: {category: {}, client: 'KOLLATV'},
            categories: [],
            isInProgress: false,
            isGalleryOpen: false,
            initCKEditor: false
        }
    }

    saveBlog = (evt) => {
        evt.preventDefault();
        let {blog} = this.state;
        this.toggleInProgress(true);
        http.post("/api/blogs", {
            id: blog.id,
            teaser: blog.teaser,
            title: blog.title,
            content: blog.content,
            imageUrl: blog.imageUrl,
            published: blog.published,
            categoryId: blog.categoryId,
            featured: blog.featured,
            author: blog.author,
            client: blog.client,
            metaDescription: blog.metaDescription
        })
            .then((response) => {
                let {blog} = response.data.content;
                if (response.data.status) {
                    window.location.href = "/admin/blog/edit?id=" + blog.id;
                } else {
                    ToastUtil.error(response.data.errors[0]);
                }
            }, (error) => {
                console.log(error)
            }).finally(() => {
            this.toggleInProgress(false)
        })

    };

    changeHandler = (evt) => {
        this.state.blog[evt.target.name] = evt.target.value;
        this.setState({blog: this.state.blog});
    };

    toggleChangeHandler = (evt) => {
        this.state.blog[evt.target.name] = !this.state.blog[evt.target.name];
        this.setState({blog: this.state.blog});
    };

    onEditorChange = (evt) => {
        this.state.blog["content"] = evt.editor.getData();
        this.setState({blog: this.state.blog});
    };

    loadBlog = (id) => {
        if (id) {
            http.get("/api/blogs/" + id)
                .then((response) => {
                    let {blog = {}} = response.data.content;
                    if (blog.category) {
                        blog.categoryId = blog.category.id;
                    }
                    this.setState({blog: blog});
                    this.setState({initCKEditor: true})
                }).catch((error) => {
                console.log(error)
            })
        } else {
            this.setState({initCKEditor: true})
        }
    };

    deleteBlog = (id) => {
        if (id) {
            http.delete("/api/blogs/" + id)
                .then((response) => {
                    window.location.href = '/admin/blog/list'
                }).catch((error) => {
                console.log(error)
            })
        } else {
            this.setState({initCKEditor: true})
        }
    };

    loadCategories = () => {
        http.get("/api/blogCategories")
            .then((response) => {
                let {categories} = response.data.content;
                this.setState({categories: categories || []});
            }).catch((error) => {
            console.log(error)
        })
    };

    getFormattedDate = (mills) => {
        return moment(new Date(mills)).tz(TIME_ZONE).format("DD-MM-yyyy HH:mm")
    };

    toggleInProgress = (bool) => {
        this.setState({isInProgress: bool});
    };

    openGallery = () => {
        this.setState({isGalleryOpen: true});
    };

    closeGallery = () => {
        this.setState({isGalleryOpen: false});
    };

    componentDidMount() {
        this.loadBlog(getParams("id"));
        this.loadCategories();
    }

    render() {
        let {blog, categories, isGalleryOpen, initCKEditor} = this.state;
        return (
            <div>
                <Breadcrumb>
                    <Breadcrumb.Item href="/admin/index">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/admin/blog/list">
                        Blogs
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Edit</Breadcrumb.Item>
                </Breadcrumb>
                <br/>
                <Row>
                    <div className="col-md-12">
                        <Form onSubmit={this.saveBlog}>
                            <Form.Group controlId="categories">
                                <Form.Label className={"font-weight-bold"}>Select Category *</Form.Label>
                                <Form.Control as="select" onChange={this.changeHandler} name={"categoryId"}
                                              value={blog.categoryId}>
                                    <option key="" value={0}>Select Category</option>
                                    {
                                        categories.map((category, index) => {
                                            return <option key={index} value={category.id}>{category.name}</option>
                                        })
                                    }
                                </Form.Control>
                                <Form.Text className="text-muted">
                                    Category is required.
                                </Form.Text>
                            </Form.Group>
                            <Form.Group controlId="categories">
                                <Form.Label className={"font-weight-bold"}>Select Client *</Form.Label>
                                <Form.Control as="select" onChange={this.changeHandler} name={"client"}
                                              value={blog.client}>
                                    {
                                        CLIENTS.map((client, index) => {
                                            return <option key={index} value={client.value}>{client.name}</option>
                                        })
                                    }
                                </Form.Control>
                            </Form.Group>

                            <Form.Group controlId="teaser">
                                <Form.Label className={"font-weight-bold"}>Teaser *</Form.Label>
                                <Form.Control type="text" placeholder="Teaser" name='teaser' required="required"
                                              value={blog.teaser} onChange={this.changeHandler}/>
                                <Form.Text className="text-muted">
                                    Maximum characters allowed 255.
                                </Form.Text>
                            </Form.Group>

                            <Form.Group controlId="title">
                                <Form.Label className={"font-weight-bold"}>Title *</Form.Label>
                                <Form.Control type="text" placeholder="Title" name='title' required="required"
                                              value={blog.title} onChange={this.changeHandler}/>
                                <Form.Text className="text-muted">
                                    Maximum characters allowed 1000.
                                </Form.Text>
                            </Form.Group>

                            <Form.Group controlId="metaDescription">
                                <Form.Label className={"font-weight-bold"}>Meta Description</Form.Label>
                                <Form.Control type="text" placeholder="Meta Description" name='metaDescription'
                                              value={blog.metaDescription} onChange={this.changeHandler}/>
                            </Form.Group>

                            <Form.Group controlId="thumbnailUrl">
                                <Form.Label className={"font-weight-bold"}>Thumbnail URL *</Form.Label>
                                <Form.Control type="text" placeholder="Thumbnail URL" name='thumbnailUrl'
                                              disabled={"disabled"}
                                              value={blog.thumbnailUrl}/>
                            </Form.Group>
                            {blog.thumbnailUrl ? <img src={blog.thumbnailUrl} width={220}/> : ''}
                            <br/>
                            <br/>
                            <Form.Group controlId="imageUrl">
                                <Form.Label className={"font-weight-bold"}>Main Image URL</Form.Label>
                                <Form.Control type="text" placeholder="Image URL" name='imageUrl'
                                              value={blog.imageUrl} onChange={this.changeHandler}/>

                                <Form.Text className="text-muted">
                                    This is the optional image. This hero image will be display on article detail page.
                                </Form.Text>

                                <Button variant="primary" className={"btn btn-sm margin-top-5px"} type="button"
                                        onClick={this.openGallery}>
                                    Browse Media for Image Url.
                                </Button>
                            </Form.Group>
                            {blog.imageUrl ?
                                <img style={{'margin-bottom': '20px'}} src={blog.imageUrl} width={220}/> : ''}

                            <Form.Group>
                                <Form.Label className={"font-weight-bold"}>Article Content</Form.Label>
                                {initCKEditor ? <CKEditor data={blog.content}
                                                          onChange={this.onEditorChange}/> : null}
                                <Button variant="primary" className={"btn btn-sm margin-top-5px"} type="button"
                                        onClick={this.openGallery}>
                                    Browse Media And Select Image URL for Blog
                                </Button>
                            </Form.Group>
                            <Form.Group controlId="author">
                                <Form.Label className={"font-weight-bold"}>Author Name</Form.Label>
                                <Form.Control type="text" placeholder="Author Name" name='author'
                                              value={blog.author} onChange={this.changeHandler}/>
                            </Form.Group>

                            {blog.publishAt ? <Form.Group controlId="publishAt">
                                <Form.Label className={"font-weight-bold"}>Publish At</Form.Label>
                                <Form.Control disabled="disabled" type="text" placeholder="Publish At"
                                              value={this.getFormattedDate(blog.publishAt)}/>
                            </Form.Group> : ''}


                            <Row>
                                <Col>
                                    <Form.Group controlId="published">
                                        <Form.Check type="checkbox" label="Published" name='published'
                                                    onChange={this.toggleChangeHandler}
                                                    checked={blog.published ? 'checked' : ''}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group controlId="featured">
                                        <Form.Check type="checkbox" label="Featured" name='featured'
                                                    onChange={this.toggleChangeHandler}
                                                    checked={blog.featured ? 'checked' : ''}/>
                                        <Form.Text className="text-muted">
                                            Featured article will be display on Start Page.
                                        </Form.Text>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <HasFeaturePermission permission={'streamly.blog.write'}>
                                <Button variant="primary" type="submit">Save</Button>
                            </HasFeaturePermission>
                            <HasFeaturePermission permission={'streamly.blog.delete'}>
                                {blog.id ? <button type="button" onClick={() => {
                                    this.deleteBlog(blog.id)
                                }} class="btn btn-danger btn-sm float-right">Delete
                                </button> : null}
                            </HasFeaturePermission>

                            &nbsp;{this.state.isInProgress ? <img src={loader}/> : ''}
                        </Form>
                    </div>

                </Row>
                <br/>
                <MediasModal show={isGalleryOpen} onHide={this.closeGallery}/>
            </div>
        )
    }
}

export default CreateOrEdit